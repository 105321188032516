$icon-collapse: "\e926";
$icon-menu: "\e927";
$icon-sublevel: "\e928";
$icon-calendar: "\e925";
$icon-dollar-circle: "\e920";
$icon-check-circle: "\e921";
$icon-delivery: "\e922";
$icon-calc: "\e923";
$icon-rouble-circle: "\e924";
$icon-star: "\e913";
$icon-star-border: "\e91d";
$icon-star-half: "\e91e";
$icon-basket: "\e91f";
$icon-smile: "\e91c";
$icon-arrow-right-round: "\e900";
$icon-new: "\e901";
$icon-edit: "\e902";
$icon-dollar: "\e903";
$icon-route-sheet: "\e904";
$icon-play: "\e905";
$icon-remove: "\e906";
$icon-mail: "\e907";
$icon-phone: "\e908";
$icon-status-not-paid: "\e909";
$icon-status-awaiting-payment: "\e90a";
$icon-status-complete: "\e90b";
$icon-status-delivery: "\e90c";
$icon-file: "\e90d";
$icon-arrow-right: "\e90e";
$icon-close: "\e90f";
$icon-arrow-up-round: "\e910";
$icon-check: "\e911";
$icon-close-round: "\e912";
$icon-scissors: "\e914";
$icon-map-point: "\e915";
$icon-percent: "\e916";
$icon-orders: "\e917";
$icon-catalog: "\e918";
$icon-account: "\e919";
$icon-cart: "\e91a";
$icon-magnifier: "\e91b";
