@import "config";

.routes {
  &-range {
    display: flex;
    align-items: center;
    margin-left: 26px;
    flex-shrink: 0;
    margin-right: 16px;

    &__line {
      width: 9px;
      height: 1px;
      flex-shrink: 0;
      margin: 0 6px;
      background: map-get($colors, text);
    }

    .form-control {
      background: #fff;
      margin: 0;
      height: 32px;

      &__wrap {
        width: 120px;
      }
    }
  }

  &-correlation {
    &__balance {
      flex-shrink: 0;
      width: 155px;

      .progress {
        background-color: map_get($colors, plan);
        margin-top: 3px;

        &-bar {
          background-color: map_get($colors, fact);
        }
      }
    }

    .form-control {
      flex-shrink: 0;
      height: 32px;
      padding: 8px;
      color: map-get($colors, plan);
      width: 74px;
    }
  }

  &-item {
    &._active {
      .routes-item__title:after {
        transform: scaleY(1);
      }
    }

    & + .routes-item {
      border-top: 1px solid map-get($colors, mgray);
    }

    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      height: 56px;
      cursor: pointer;
    }

    &__title {
      &:after {
        content: $icon-arrow-up-round;
        font-family: $font_icomoon;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 6px;
        color: #a09fa7;
        pointer-events: none;
        transform: scaleY(-1);
        transition: transform .2s;
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
      }
    }

    &__body {
      padding: 0 8px 8px;

      .routes__client {
        .routes__list ~ .routes-item__row {
          &-info,
          &-buttons,
          &-tasks {
            margin-right: 277px;
          }
        }
      }
    }

    &__row {
      &-info {
        display: flex;
        margin-bottom: 16px;
        align-items: flex-start;
      }

      &-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-tasks {
        margin-top: 16px;
        border-radius: 8px;
        border: 1px solid map-get($colors, mgray);
        background: map-get($colors, lgray);
        position: relative;

        &._active {
          .accordion__title {
            width: 140px;

            &:after {
              margin-top: 1px;
            }
          }
        }

        .accordion {
          &__title {
            height: 48px;
            padding: 0 0 0 16px;
            display: flex;
            align-items: center;
            font-size: 16px;

            &:after {
              margin-top: 3px;
            }
          }

          &__body {
            background: #fff;
            border-radius: 0 0 8px 8px;
          }
        }
      }
    }

    &-date {
      position: relative;
      margin-right: 16px;
      flex-shrink: 0;

      &[data-state='active'] {
        .routes-item-date__popover {
          opacity: 1;
          pointer-events: auto;
        }
      }

      &__btn {
        &-edit {
          width: 114px;
          padding-left: 16px;
          padding-right: 12px;
          font-weight: 400;
          justify-content: space-between;
          flex-shrink: 0;

          .icon {
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            opacity: .4;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      &__popover {
        position: absolute;
        top: 0;
        left: 0;
        height: 48px;
        border-radius: 3px;
        padding: 8px 12px 8px 8px;
        display: flex;
        align-items: center;
        background: map_get($colors, mgray);
        transition: opacity .2s;
        opacity: 0;
        pointer-events: none;
        z-index: 5;

        .form-control {
          background: #fff;
          margin: 0;
          height: 32px;

          &__wrap {
            flex-shrink: 0;
            width: 95px;
          }
        }

        .btn {
          &-primary {
            width: 100px;
            flex-shrink: 0;
            margin-left: 8px;
          }

          &-icon {
            width: 24px;
            height: 24px;
            min-height: 0;
            font-size: 14px;
            opacity: .4;
            margin-left: 12px;
            transition: opacity .2s !important;

            &:hover {
              opacity: 1;
            }
          }
        }
      }

      .routes-item__btn-visited {
        display: none;
        width: 100%;
      }
    }

    &__name {
      margin-right: 16px;
    }

    &__info {
      margin-left: auto;
    }

    &__btn {
      &-toggle {
        flex-shrink: 0;
        margin-right: 16px;

        .icon {
          opacity: .4;
          font-size: 8px;
          transition: transform .2s;
          transform: scaleY(-1);
        }
      }
    }
  }

  &-tasks {
    &__form {
      display: flex;
      align-items: center;
      position: absolute;
      top: 8px;
      right: 16px;
      left: 150px;

      .form-control {
        height: 32px;
        margin-right: 16px;
        background: #fff;
      }

      .btn {
        min-width: 100px;
        flex-shrink: 0;
      }
    }

    &__item {
      border-top: 1px solid map-get($colors, mgray);
      padding: 12px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .custom {
        &-checkbox {
          .custom-control-input {
            &:checked {
              & ~ .custom-control-label {
                color: map_get($colors, green);

                &:before {
                  background-color: map_get($colors, mgray);
                }
              }
            }
          }

          .custom-control-label {
            transition: color .2s;

            &:after {
              color: #94949D;
            }
          }
        }
      }

      form {
        margin: -5px -53px -5px 0;
        width: 100%;
      }

      .form-control {
        height: 32px;
        padding-right: 40px;
        padding-left: 10px;
      }
    }

    &__controls {
      flex-shrink: 0;
      display: flex;
      margin-left: 16px;

      .icon {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        opacity: .4;
        transition: opacity .2s;
        font-size: 14px;
        text-align: center;
        line-height: 24px;
        margin-left: 8px;
        cursor: pointer;

        @include hover() {
          opacity: .8;
        }
      }
    }

    &__circle {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      border-radius: 50%;
      background-color: map_get($colors, primary);
      opacity: .5;
      pointer-events: none;
      margin-right: 4px;
    }
  }

  &__dropdown-brands {
    margin-left: auto;
    flex-shrink: 0;
    width: 190px;
    margin-right: 16px;
    font-weight: 400;
  }

  &__list {
    border-radius: 8px;
    border: 1px solid map-get($colors, mgray);
  }

  &__client {
    padding: 16px;

    &._active {
      .routes-item__btn-toggle {
        .icon {
          transform: scaleY(1);
        }
      }
    }

    &._closed {
      .routes__list {
        opacity: .4;
      }

      .routes-item__row {
        &-info,
        &-tasks {
          opacity: .4;
        }
      }
    }

    & + .routes__client {
      border-top: 1px solid map-get($colors, mgray);
    }

    .routes__list {
      float: right;
      margin: -8px -8px -8px 0;

      & ~ .routes-item__row {
        &-info,
        &-buttons {
          margin-right: 187px;
        }
      }

      .accordion {
        & + .accordion {
          border-top: 1px solid map-get($colors, mgray);
        }

        &__title {
          padding: 12px 16px;

          &:not(.js-toggle-accordion) {
            cursor: default;

            &:after {
              display: none;
            }
          }
        }
      }
    }

    .routes-correlation {
      padding: 0 8px 8px 16px;
    }
  }

  .board__title {
    height: 84px;
    padding-top: 0;
    padding-bottom: 0;

    .routes-correlation {
      font-weight: 400;
      margin-right: 16px;
    }
  }
}


@media ($media_max_tablet) {
  .routes {
    &-correlation {
      &__balance {
        width: 115px;
      }
    }

    &__title {
      width: 105px;
      flex-shrink: 0;
    }

    &-item {
      &__body {
        .routes__client {
          .routes__list ~ .routes-item__row {
            &-info,
            &-buttons,
            &-tasks {
              margin-right: 240px;
            }
          }
        }
      }
    }

    &__client {
      .routes__list {
        & ~ .routes-item__row {
          &-info,
          &-buttons {
            margin-right: 150px;
          }
        }
      }
    }

    .board__title {
      .routes-correlation {
        margin-right: 0;
      }
    }
  }
}

@media ('max-width: 900px') {
  .routes {
    &-range {
      margin-left: 0;
    }

    &__title {
      width: 100%;
      margin-bottom: 32px;
      margin-top: 16px;
    }

    .board__title {
      flex-wrap: wrap;
      overflow: visible;
      height: 56px;
      margin-bottom: 100px;
    }
  }
}

@media ($media_max_phone_landscape) {
  .routes {
    &-range {
      margin: 0;
      width: 100%;

      .form-control__wrap {
        width: 100%;
      }
    }

    &-correlation {
      &__balance {
        width: 155px;
      }
    }

    &-item {
      &:last-child {
        .routes-item__body > .routes__list {
          border-radius: 0 0 8px 8px;
        }

        .routes-item__row-tasks {
          border-radius: 0 0 8px 8px;

          .accordion__body {
            border-radius: 0 0 8px 8px;
          }
        }
      }

      &__body {
        padding: 0;

        .routes__list {
          border-left: 0;
          border-right: 0;
          border-bottom: 0;
          border-radius: 0;
        }
      }

      &__row {
        &-info,
        &-buttons,
        &-tasks {
          margin-right: 0 !important;
          flex-shrink: 0;
        }

        &-info {
          order: 0;
          display: block;
        }

        &-buttons {
          order: 1;
          margin-bottom: 16px;

          .routes-item__btn-visited {
            display: none;
          }
        }

        &-tasks {
          order: 3;
          margin: 0 -16px;
          width: calc(100% + 32px);
          border-radius: 0;
          border: 0;
          border-top: 1px solid map-get($colors, mgray);

          &._active {
            .accordion__title {
              width: auto;
            }
          }

          .accordion__body {
            border-radius: 0;
          }
        }
      }

      &-date {
        width: auto;
        margin: 0 0 16px;
        display: flex;
        align-items: flex-start;

        &__popover {
          height: auto;
          padding: 8px;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 224px;

          .form-control {
            &__wrap {
              width: 168px;
              margin-bottom: 8px;
            }
          }

          .btn {
            &-primary {
              margin: 0;
            }

            &-icon {
              position: absolute;
              top: 12px;
              right: 12px;
            }
          }
        }

        .routes-item__btn-visited {
          display: flex;
        }
      }

      &__name {
        margin-bottom: 16px;
      }

      &__info {
        margin: 0;
      }

      &__btn {
        &-add {
          width: 100%;
        }
      }
    }

    &-tasks {
      &__form {
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        padding: 12px 16px;
        border-top: 1px solid map-get($colors, mgray);

        .form-control {
          background: map-get($colors, lgray);
        }

        .btn {
          min-width: 80px;
        }
      }
    }

    &__dropdown-brands {
      margin: 8px 0 16px;
      width: 100%;

      .dropdown__body {
        padding-left: 12px;
      }
    }

    &__client {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;

      .routes__list {
        float: none;
        margin: 0 -16px;
        order: 2;
        flex-shrink: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        border-radius: 0;
      }

      .clearfix {
        display: none !important;
      }
    }

    .board__title {
      margin-bottom: 186px;

      .routes-correlation {
        margin: 0 0 0 16px;
      }
    }
  }
}
