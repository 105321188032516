@import "config";
@import "icon_names";

.tip {
  transition: margin .2s, opacity .2s, transform 0s .2s;
  opacity: 0;
  pointer-events: none;
  bottom: 100%;
  left: 50%;
  transform: translateX(-100%);
  border-radius: 4px;
  background: #28282c;
  padding: 8px;
  position: absolute;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  z-index: 10;
  width: 215px;

  &-trigger {
    position: relative;
    cursor: help;

    &:hover {
      .tip {
        opacity: 1;
        margin-bottom: 8px;
        transform: translateX(-50%);
        transition: margin .2s, opacity .2s, transform 0s 0s;

        &._bottom {
          margin-bottom: 0;
          margin-top: 8px;
        }
      }
    }
  }

  &-title {
    font-size: 15px;
    line-height: 18px;
  }

  &-text {
    color: #aeb0b5;
  }

  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    border: 8px solid transparent;
    border-top: 5px #28282c;
  }

  &._bottom {
    top: 100%;
    bottom: auto;

    &:before {
      border: 8px solid transparent;
      top: auto;
      bottom: 100%;
      border-bottom: 5px solid #28282c;
    }
  }

  &._left {
    &:before {
      left: 30px;
    }
  }
}

.board {
  $padding: 16px;
  $border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
  border-radius: $border-radius;
  background-color: #fff;
  padding: $padding;
  margin-bottom: $padding;

  &__title {
    min-height: 56px;
    background-color: map_get($colors, lgray);
    border-radius: $border-radius $border-radius 0 0;
    padding: 16px;
    display: flex;
    align-items: center;
    margin: $padding * -1 $padding * -1 $padding;
  }

  &_border {
    box-shadow: none;
    border: 1px solid map_get($colors, mgray);

    .board__title {
      box-shadow: 0 1px 0 map_get($colors, mgray);
    }
  }

  &_p-lg {
    $padding: 32px;
    padding: $padding;

    .board__title {
      margin: $padding * -1 $padding * -1 $padding;
    }
  }

  &_p-sm {
    $padding: 8px;
    padding: $padding;

    .board__title {
      margin: $padding * -1 $padding * -1 $padding;
    }
  }

  &_p-none {
    padding: 0;

    .board__title {
      margin: 0;
    }
  }
}

.link {
  &-abs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.form {
  &-search {
    position: relative;

    &__submit {
      position: absolute;
      top: 7px;
      left: 7px;
      width: 18px;
      opacity: .4;
      transition: opacity .2s;

      &:disabled {
        opacity: .2;
      }

      &:not(:disabled) {
        @include hover() {
          opacity: .8;
        }
      }
    }

    .form-control {
      box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
      height: 32px;
      background-color: #fff;
      border: none;
      padding-left: 32px;
      width: 100%;
    }
  }
}

.icon {
  &-text {
    display: flex;
    align-items: center;
    color: map_get($colors, text);

    &__icon {
      font-size: 20px;
      margin-right: 6px;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      flex-shrink: 0;
    }

    &__text {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

a.icon-text {
  opacity: .4;

  @include hover() {
    opacity: .8;
    text-decoration: none;
  }
}

.img {
  &-tile {
    $padding: 5px;
    display: flex;
    margin: $padding * -1;
    flex-wrap: wrap;

    &__col {
      flex: 0 0 50%;
      padding: $padding;
    }

    &-item {
      box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
      border-radius: 4px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      &__space {
        pointer-events: none;
        padding-bottom: 50%;
      }
    }
  }

  &-fluid {
    display: block;
    width: 100%;
    border-radius: 4px;
  }

  &-cover {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.lead {
  font-size: 24px;
  line-height: 32px;
}

.paragraph {
  font-size: 20px;
  line-height: 32px;

  &_indent {
    text-indent: 44px;

    p {
      margin: 0;
      padding: 0;
    }
  }
}

.list {
  list-style: none;
  padding: 0;

  li {
    font-size: 16px;
    line-height: 24px;
    position: relative;
    min-height: 24px;
    padding-left: 32px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: map_get($colors, primary);
    }

    & + li {
      margin-top: 10px;
    }
  }
}

ol.list {
  counter-reset: num;

  li {
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      text-align: center;
      line-height: 24px;
      font-size: 14px;
      color: #fff;
      counter-increment: num;
      content: counter(num);
      pointer-events: none;
    }
  }
}

.news {
  $padding: 8px;
  display: flex;
  flex-wrap: wrap;
  margin: $padding * -1 - 2 $padding * -1;

  &__col {
    flex: 0 0 50%;
    padding: $padding + 2 $padding;
  }

  &-item {
    position: relative;
    display: flex;
    align-items: flex-end;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 80px;

    &:last-child:first-child {
      height: 100%;
    }

    & + .news-item {
      margin-top: 20px;
    }

    &__body {
      position: relative;
      padding: 14px 24px;
      background-color: map_get($colors, lgray);
      width: 100%;
      border-radius: 0 0 4px 4px;
    }

    &__title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 6px;
    }
  }
}

.pages {
  margin-top: 16px;
  display: flex;

  &__item {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin-left: 5px;
    line-height: 30px;
    text-align: center;
    font-weight: 700;
    transition: background-color .2s, color .2s;
    color: map_get($colors, text);
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &._active {
      background-color: map_get($colors, secondary);
      color: #fff;
      text-decoration: none;
    }

    @include hover() {
      background-color: map_get($colors, dgray);
      color: map_get($colors, text);
      text-decoration: none;
    }

    &._active {
      cursor: default;
    }
  }

  &__arrow {
    background-color: map_get($colors, lgray) !important;
    color: map_get($colors, dgray);
    display: flex;
    align-items: center;
    justify-content: center;

    &_left {
      transform: scaleX(-1);
    }

    @include hover() {
      color: map_get($colors, text);
    }

    .icon {
      font-size: 18px;
      margin-left: 2px;
      display: block;
    }
  }

  &__text {
    pointer-events: none;
  }
}

.notification {
  border-radius: 4px;
  background-color: map_get($colors, secondary);
  display: flex;
  align-items: center;
  padding: 16px;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 24px;
  color: #fff;

  div {
    flex-shrink: 0;
  }
}

.sorting {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &__arrows {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__arrow {
    flex-shrink: 0;
    width: 18px;
    height: 17px;
    opacity: .4;
    transition: opacity .2s;
    cursor: pointer;
    text-align: center;
    margin: 1px 0;

    &_down {
      transform: scaleY(-1);
    }

    &:before {
      content: $icon-arrow-up-round;
      font-size: 6px;
      font-family: $font_icomoon;
      line-height: 19px;
      display: block;
      pointer-events: none;
    }

    &._active {
      opacity: 1;
    }

    @include hover() {
      opacity: 1;
    }
  }

  &__text {
    margin-right: 12px;
  }
}

.download-file {
  border: 1px solid map_get($colors, mgray);
  width: 200px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 15px;
  border-radius: 3px;

  @include hover() {
    .download-file__icon {
      opacity: 1;
    }

    .download-file__name {
      text-decoration: underline;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 16px;
    opacity: .4;
    transition: opacity .2s;
    font-size: 20px;
    text-align: center;
    line-height: 24px;
  }

  &__body {
    width: 100%;
  }

  &__name {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
  }

  &__type {
    opacity: .4;
  }
}

.avatar {
  $width: 100px;
  width: $width;
  height: $width;
  border: 2px solid map_get($colors, mgray);
  border-radius: 3px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &:before {
    content: $icon-smile;
    font-family: $font_icomoon;
    position: absolute;
    background-color: map_get($colors, lgray);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    text-align: center;
    line-height: $width - 2;
    font-size: 50px;
    color: rgba(17, 15, 36, .4);
    display: none;
  }

  &_blank {
    &:before {
      display: block;
    }
  }
}

.credit-card {
  width: 205px;
  height: 130px;
  border-radius: 5px;
  overflow: hidden;
  background: url($img_dir + "credit_card_bg.svg") 0 bottom no-repeat map_get($colors, primary);
  background-size: 100% auto;
  position: relative;
  color: #fff;
  padding: 32px 18px 0;
  font-size: 12px;

  &_mastercard {
    .credit-card__logo {
      $height: 20px;
      width: 31px;
      height: $height;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        width: $height;
        height: $height;
        border-radius: 50%;
      }

      &:before {
        background-color: map_get($colors, red);
        left: 0;
      }

      &:after {
        background-color: map_get($colors, orange);
        right: 0;
      }
    }
  }

  &__row {
    flex-wrap: nowrap;
    overflow: auto;
    position: relative;

    .col {
      flex-grow: 0;
    }
  }

  &__logo {
    position: absolute;
    pointer-events: none;
    top: 16px;
    right: 16px;
  }
}

.product {
  &-preview {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.attention {
  padding: $content_layout_padding 60px $content_layout_padding $content_layout_padding;
  min-height: $header_top_height;
  position: relative;

  &-wrap {
    position: fixed;
    width: calc(100% - #{$menu_width});
    top: 0;
    left: $menu_width;
    z-index: 110;

    .column-main & {
      opacity: 0;
      pointer-events: none;
      position: relative;
      top: auto;
      left: auto;
      width: auto;
      margin: 0 $content_layout_padding * -1;
      flex-shrink: 0;
    }
  }

  &__close {
    top: $content_layout_padding;
    right: $content_layout_padding;

    @include hover() {
      opacity: .8;
    }
  }
}

.accordion {
  &._active {
    > .accordion__title {
      &:after {
        transform: scaleY(1);
      }
    }

    > .accordion__body {
      display: block;
    }
  }

  > .accordion__title {
    cursor: pointer;

    &:after {
      content: $icon-arrow-up-round;
      font-family: $font_icomoon;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      vertical-align: middle;
      font-size: 6px;
      color: #a09fa7;
      margin-left: 12px;
      pointer-events: none;
      transition: transform .2s;
      transform: scaleY(-1);
    }
  }

  > .accordion__body {
    display: none;
    overflow: hidden;
  }
}

.counter {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px map_get($colors, mgray) inset;
  display: inline-flex;
  overflow: hidden;

  &__btn {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    position: relative;
    color: map_get($colors, dgray);
    cursor: pointer;
    transition: color .2s;

    @include hover() {
      color: #666;
    }

    &:before,
    &:after {
      position: absolute;
      pointer-events: none;
      height: 1px;
      background-color: currentColor;
      top: 50%;
      left: 50%;
      width: 11px;
      margin-left: -5px;
    }

    &_minus {
      &:before {
        content: "";
      }
    }

    &_plus {
      &:before {
        content: "";
      }

      &:after {
        content: "";
        transform: rotate(90deg);
      }
    }
  }

  &__input {
    width: 32px;
    border: 0;
    text-align: center;
    height: 32px;
    font-family: $font_lato;
    font-size: 14px;
    font-weight: 700;
    color: #000;
    padding: 0;
    margin: 0;
    background: transparent;
  }
}

.label {
  &-action {
    position: relative;
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    background-color: map_get($colors, red);
    border-radius: 4px;
    padding: 0 5px;
    height: 20px;
    line-height: 20px;
    z-index: 10;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      border-radius: 4px;
      background-color: map_get($colors, red);
      width: 16px;
      height: 16px;
      transform: rotate(45deg);
      top: 2px;
      left: -5px;
      z-index: -1;
    }
  }
}

.balance {
  width: 100%;
  height: 40px;
  border-radius: 3px;
  border: 1px solid map_get($colors, mgray);
  display: flex;

  &__group {
    width: 100%;
    display: flex;
    height: 100%;
    border-radius: 3px;
    box-shadow: 0 0 0 1px map_get($colors, dgray);
    min-width: 190px;
  }

  &__plan,
  &__fact {
    width: 50%;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    white-space: nowrap;
  }

  &__plan {
    color: map_get($colors, plan);
  }

  &__fact {
    text-align: right;
    justify-content: flex-end;
    color: map_get($colors, fact);
    border-left: 1px solid map_get($colors, dgray);
  }

  &__percent {
    flex-shrink: 0;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding: 0 5px;
    text-align: center;
    height: 100%;
    min-width: 54px;
    justify-content: center;
  }
}

.pack-head {
  display: flex;
  border-radius: 8px;
  padding: 7px 16px;
  min-height: 48px;
  background: map-get($colors, primary-o6);
  border: 1px solid map-get($colors, "mgray");
  margin-top: 16px;
  cursor: pointer;
  color: #fff;
  position: relative;
  align-items: center;
  justify-content: space-between;

  &__title {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    margin-right: 16px;
  }

  &__text {
    font-size: 14px;
    line-height: 16px;
    text-align: right;
  }

  &__icon {
    display: none;
    font-size: 14px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fff;
    flex-shrink: 0;
    color: map-get($colors, primary);
    align-items: center;
    justify-content: center;
  }

  .custom {
    &-checkbox {
      .custom-control-input {
        &:checked,
        &:disabled:checked {
          & ~ .custom-control-label {
            &:before {
              background-color: map_get($colors, secondary-o6);
            }

            &:after {
              opacity: 1;
            }
          }
        }
      }
    }

    &-switch {
      .custom-control-input {
        &:checked {
          & ~ .custom-control-label {
            &:before {
              background-color: map_get($colors, secondary-o6);
            }
          }
        }
      }

      .custom-control-label {
        &:before {
          background-color: map_get($colors, secondary-o6);
        }
      }
    }
  }

  &:not(._disabled)._expanded {
    border-radius: 8px 8px 0 0;
    border-color: map-get($colors, primary);
    background: #fff;
    cursor: default;
    color: map-get($colors, primary);

    .pack-head__title {
      color: map-get($colors, primary);
    }

    .pack-head__text {
      color: map-get($colors, red);
    }

    .custom {
      &-checkbox {
        .custom-control-input {
          &:checked,
          &:disabled:checked {
            & ~ .custom-control-label {
              &:before {
                background-color: map_get($colors, secondary);
              }

              &:after {
                opacity: 1;
              }
            }
          }
        }
      }

      &-switch {
        .custom-control-input {
          &:checked {
            & ~ .custom-control-label {
              &:before {
                background-color: map_get($colors, secondary);
              }
            }
          }
        }

        .custom-control-label {
          &:before {
            background-color: map_get($colors, secondary);
          }
        }
      }
    }
  }

  &:not(._disabled)._completed {
    border-color: map-get($colors, primary);
    background: map-get($colors, primary);
    color: #fff;

    .pack-head__title {
      color: #fff;
    }

    .pack-head__text {
      display: none;
    }

    .pack-head__icon {
      display: flex;
    }

    .custom {
      &-checkbox {
        .custom-control-input {
          &:checked,
          &:disabled:checked {
            & ~ .custom-control-label {
              &:before {
                background-color: map_get($colors, secondary);
              }

              &:after {
                opacity: 1;
              }
            }
          }
        }
      }

      &-switch {
        .custom-control-input {
          &:checked {
            & ~ .custom-control-label {
              &:before {
                background-color: map_get($colors, secondary);
              }
            }
          }
        }

        .custom-control-label {
          &:before {
            background-color: map_get($colors, secondary);
          }
        }
      }
    }
  }

  &._disabled {
    pointer-events: none;
    background: #c6c5ca;

    & + .table-shadow {
      display: none;
    }
  }
}


@media ($media_min_phone_landscape) {
  .dashboard {
    .row {
      margin: 0 -22px;
    }

    .col {
      padding: 0 22px;
    }
  }
}

@media ($media_max_tablet_landscape) {
  .img {
    &-tile {
      $padding: 4px;
      margin: $padding * -1;

      &__col {
        padding: $padding;
      }
    }
  }

  .news {
    $padding: 10px;
    margin: $padding * -1;

    &__col {
      padding: $padding;
    }
  }

  .attention {
    &-wrap {
      width: 100%;
      left: 0;
    }
  }
}

@media ($media_max_phone_landscape) {
  .board {
    .column-main > & {
      border-radius: 0;
    }

    &__title {
      border-radius: 0;
    }
  }

  .img {
    &-tile {
      display: block;
      margin: 0;

      &__col {
        padding: 0;

        & + .img-tile__col {
          margin-top: 8px;
        }
      }

      &-item {
        &__space {
          padding-bottom: 47%;
        }
      }
    }
  }

  .news {
    display: block;
    margin: 0;

    &__col {
      padding: 0;

      &:first-child {
        .news-item {
          padding-top: 145px;
        }
      }

      & + .news__col {
        margin-top: 16px;
      }
    }

    &-item {
      padding-top: 73px;

      &:last-child:first-child {
        height: auto;
      }

      & + .news-item {
        margin-top: 16px;
      }

      &__body {
        padding: 16px 14px;
      }

      &__title {
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
  }

  .pages {
    justify-content: center;
  }

  .notification {
    display: block;
  }

  .attention {
    &-wrap {
      .column-main & {
        margin: 0;

        &:not(:empty) {
          margin: $header_top_height * -1 0 16px;
        }
      }
    }
  }

  .add-clients {
    max-width: none;
  }

  .balance {
    max-width: none;
    width: auto;
    height: 32px;
  }

  .pack-head {
    display: block;

    &__text {
      margin-top: 8px;
      text-align: left;
    }

    &._completed {
      display: flex;
    }
  }
}
