@import "config";

.my-manager {
  max-width: 568px;

  &-info {
    display: flex;
    margin-bottom: 30px;

    &__avatar {
      flex-shrink: 0;
      margin-right: 20px;
    }

    &__body {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &__name {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: auto;
      padding-bottom: 16px;
    }

    .icon-text {
      margin-bottom: 7px;

      &__icon {
        width: 16px;
        height: 16px;
        font-size: 13px;
        opacity: .4;
        line-height: 16px;
      }

      a {
        color: #000;
      }
    }
  }

  &-form {
    label {
      margin-bottom: 12px;
      font-weight: 700;
    }

    button {
      max-width: 200px;
    }
  }
}


@media ($media_max_phone_landscape) {
  .my-manager {
    max-width: none;

    &-form {
      button {
        max-width: none;
      }
    }
  }
}
