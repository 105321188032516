@import "config";
@import "icon_names";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 30px 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 200;

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .55);

    &._transparent {
      background: none;
    }
  }

  &__body {
    flex-shrink: 0;
    position: relative;
    width: 100%;
    max-width: 400px;
    border-radius: 8px;
    background: #fff;
    padding: $content_layout_padding;
    overflow: hidden;
    margin: auto 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

    &._promotion-detail {
      height: 100%;
      max-width: 1024px;
      max-height: 768px;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-enter {
    .popup__bg,
    .popup__body {
      opacity: 0;
    }
  }

  &-enter-active {
    .popup__bg,
    .popup__body {
      opacity: 1;
      transition: opacity 0.3s ease-out;
    }
  }

  &-exit {
    .popup__bg,
    .popup__body {
      opacity: 1;
    }
  }

  &-exit-active {
    .popup__bg,
    .popup__body {
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
}


@media ($media_max_phone_landscape) {
  .popup {
    padding: $content_layout_padding;
  }
}
