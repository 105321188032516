@import 'config';
@import 'icon_names';

.icon {
  font-family: $font_icomoon;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-collapse {
  &:before {
    content: $icon-collapse;
  }
}

.icon-menu {
  &:before {
    content: $icon-menu;
  }
}

.icon-sublevel {
  &:before {
    content: $icon-sublevel;
  }
}

.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}

.icon-dollar-circle {
  &:before {
    content: $icon-dollar-circle;
  }
}

.icon-check-circle {
  &:before {
    content: $icon-check-circle;
  }
}

.icon-delivery {
  &:before {
    content: $icon-delivery;
  }
}

.icon-calc {
  &:before {
    content: $icon-calc;
  }
}

.icon-rouble-circle {
  &:before {
    content: $icon-rouble-circle;
  }
}

.icon-star {
  &:before {
    content: $icon-star;
  }
}

.icon-star-border {
  &:before {
    content: $icon-star-border;
  }
}

.icon-star-half {
  &:before {
    content: $icon-star-half;
  }
}

.icon-basket {
  &:before {
    content: $icon-basket;
  }
}

.icon-smile {
  &:before {
    content: $icon-smile;
  }
}

.icon-arrow-right-round {
  &:before {
    content: $icon-arrow-right-round;
  }
}

.icon-new {
  &:before {
    content: $icon-new;
  }
}

.icon-edit {
  &:before {
    content: $icon-edit;
  }
}

.icon-dollar {
  &:before {
    content: $icon-dollar;
  }
}

.icon-route-sheet {
  &:before {
    content: $icon-route-sheet;
  }
}

.icon-play {
  &:before {
    content: $icon-play;
  }
}

.icon-remove {
  &:before {
    content: $icon-remove;
  }
}

.icon-mail {
  &:before {
    content: $icon-mail;
  }
}

.icon-phone {
  &:before {
    content: $icon-phone;
  }
}

.icon-status-not-paid {
  &:before {
    content: $icon-status-not-paid;
  }
}

.icon-status-awaiting-payment {
  &:before {
    content: $icon-status-awaiting-payment;
  }
}

.icon-status-complete {
  &:before {
    content: $icon-status-complete;
  }
}

.icon-status-delivery {
  &:before {
    content: $icon-status-delivery;
  }
}

.icon-file {
  &:before {
    content: $icon-file;
  }
}

.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}

.icon-close {
  &:before {
    content: $icon-close;
  }
}

.icon-arrow-up-round {
  &:before {
    content: $icon-arrow-up-round;
  }
}

.icon-check {
  &:before {
    content: $icon-check;
  }
}

.icon-close-round {
  &:before {
    content: $icon-close-round;
  }
}

.icon-scissors {
  &:before {
    content: $icon-scissors;
  }
}

.icon-map-point {
  &:before {
    content: $icon-map-point;
  }
}

.icon-percent {
  &:before {
    content: $icon-percent;
  }
}

.icon-orders {
  &:before {
    content: $icon-orders;
  }
}

.icon-catalog {
  &:before {
    content: $icon-catalog;
  }
}

.icon-account {
  &:before {
    content: $icon-account;
  }
}

.icon-cart {
  &:before {
    content: $icon-cart;
  }
}

.icon-magnifier {
  &:before {
    content: $icon-magnifier;
  }
}
