@import "config";

// 1 - 4
// 2 - 8
// 3 - 16
// 4 - 24
// 5 - 48

.w {
  $sizes: 1, 9, 24, 42, 53, 60, 70, 80, 85, 98, 110, 120, 126, 130, 146, 158, 162, 180, 200, 215, 235, 250, 280;

  @each $size in $sizes {
    &-#{$size} {
      width: #{$size}px !important;
    }
  }

  $sizes: 680, 700, 752;

  @each $size in $sizes {
    &-max-#{$size} {
      max-width: #{$size}px !important;
    }
  }

  $sizes: 200;

  @each $size in $sizes {
    &-min-#{$size} {
      min-width: #{$size}px !important;
    }
  }

  $sizes: 1;

  @each $size in $sizes {
    &-#{$size}p {
      width: #{$size + 0%} !important;
    }
  }
}

.h {
  $sizes: 1, 48, 275;

  @each $size in $sizes {
    &-#{$size} {
      height: #{$size}px !important;
    }
  }

  $sizes: 500;

  @each $size in $sizes {
    &-max-#{$size} {
      max-height: #{$size}px !important;
    }
  }

  $sizes: 220;

  @each $size in $sizes {
    &-min-#{$size} {
      min-height: #{$size}px !important;
    }
  }
}

.m {
  $sizes: 6, 12, 32;

  @each $size in $sizes {
    &t-#{$size} {
      margin-top: #{$size}px !important;
    }

    &l-#{$size} {
      margin-left: #{$size}px !important;
    }

    &r-#{$size} {
      margin-right: #{$size}px !important;
    }

    &b-#{$size} {
      margin-bottom: #{$size}px !important;
    }

    &x-#{$size} {
      margin-left: #{$size}px !important;
      margin-right: #{$size}px !important;
    }
  }
}

.p {
  $sizes: 10, 12, 20;

  @each $size in $sizes {
    &t-#{$size} {
      padding-top: #{$size}px !important;
    }

    &l-#{$size} {
      padding-left: #{$size}px !important;
    }

    &x-#{$size} {
      padding-left: #{$size}px !important;
      padding-right: #{$size}px !important;
    }
  }

  $sizes: 45;

  @each $size in $sizes {
    &t-#{$size}p {
      padding-top: #{$size + 0%} !important;
    }
  }
}

.f {
  &z {
    $sizes: 8, 12, 14, 16, 20, 24, 40;

    @each $size in $sizes {
      &-#{$size} {
        font-size: #{$size}px !important;
      }
    }
  }

  &w {
    $sizes: 100, 300, 400, 500, 700, 900;

    @each $size in $sizes {
      &-#{$size} {
        font-weight: #{$size} !important;
      }
    }
  }
}

.lh {
  $sizes: 16, 20, 24, 32, 48;

  @each $size in $sizes {
    &-#{$size} {
      line-height: #{$size}px !important;
    }
  }

  &-1 {
    line-height: 1 !important;
  }
}

.s {
  $sizes: 8, 20, 24;

  @each $size in $sizes {
    &-#{$size} {
      width: #{$size}px !important;
      height: #{$size}px !important;
    }
  }
}

.c {
  @each $name, $color in $colors {
    &-#{$name} {
      color: $color !important;
    }

    &-bg-#{$name} {
      background-color: $color !important;
    }

    &-hover-#{$name} {
      &:hover {
        color: $color !important;
      }
    }

    &-hover-bg-#{$name} {
      &:hover {
        background-color: $color !important;
      }
    }
  }
}

.o {
  $sizes: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9;

  @each $size in $sizes {
    &-#{$size} {
      opacity: #{$size/10} !important;
    }
  }
}

.sm {
  $sizes: 16;

  @each $size in $sizes {
    &t-#{$size} {
      .sm-item + .sm-item {
        margin-top: #{$size}px !important;
      }
    }
  }
}

.br {
  $sizes: 8;

  @each $size in $sizes {
    &-#{$size} {
      border-radius: #{$size}px !important;
    }

    &-t-#{$size} {
      border-radius: #{$size}px #{$size}px 0 0 !important;
    }
  }
}

.border {
  @each $name, $color in $colors {
    &-#{$name} {
      border: 1px solid $color !important;
    }

    &-shadow-#{$name} {
      box-shadow: 0 0 0 1px $color !important;
    }
  }
}

.pe {
  &-n {
    pointer-events: none !important;
  }

  &-a {
    pointer-events: auto !important;
  }
}

.cur {
  &-p {
    cursor: pointer;
  }
}

.text {
  &-noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &-nodecoration {
    text-decoration: none !important;
  }
}

.transition {
  transition: all .2s !important;

  &-color {
    transition: color .2s, background .2s !important;
  }

  &-opacity {
    transition: opacity .2s !important;
  }
}

.interactive {
  opacity: .4;
  transition: opacity .2s;
  cursor: pointer;

  @include hover() {
    opacity: .8;
  }
}

.rotate {
  $sizes: 45;

  @each $size in $sizes {
    &-#{$size} {
      transform: rotate(#{$size}deg) !important;
    }
  }
}

.disabled {
  pointer-events: none;
}

.z {
  $sizes: 0, 1, 2, 3, 4;

  @each $size in $sizes {
    &-#{$size} {
      z-index: #{$size} !important;
    }
  }

  &-auto {
    z-index: auto !important;
  }
}

.shadow {
  &-1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
  }
}


@media ($media_min_tablet) {
  .w-lg-100 {
    width: 100% !important;
  }
}

@media ($media_min_phone_landscape) {
  .f {
    &z {
      $sizes: 20;

      @each $size in $sizes {
        &-md-#{$size} {
          font-size: #{$size}px !important;
        }
      }
    }
  }

  .lh {
    $sizes: 24;

    @each $size in $sizes {
      &-md-#{$size} {
        line-height: #{$size}px !important;
      }
    }
  }

  .h {
    $sizes: 400;

    @each $size in $sizes {
      &-md-#{$size} {
        height: #{$size}px !important;
      }
    }
  }

  .m {
    &r-sm-n56 {
      margin-right: -56px !important;
    }
  }

  .w {
    $sizes: 180, 215;

    @each $size in $sizes {
      &-md-#{$size} {
        width: #{$size}px !important;
      }
    }

    $sizes: 440;

    @each $size in $sizes {
      &-md-max-#{$size} {
        max-width: #{$size}px !important;
      }
    }

    $sizes: 33, 50;

    @each $size in $sizes {
      &-md-#{$size}p {
        width: #{$size + 0%} !important;
      }
    }

    &-md-auto {
      width: auto !important;
    }
  }
}

@media ($media_min_phone) {
  .m {
    $sizes: 40, 60;

    @each $size in $sizes {
      &r-sm-#{$size} {
        margin-right: #{$size}px !important;
      }
    }
  }

  .h-sm-max-100 {
    max-height: 100px !important;
  }

  .w-sm-auto {
    width: auto !important;
  }
}
