@import 'config';

.catalog {
  &__filter {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6px;

    .btn {
      flex-shrink: 0;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 20px 0;
    position: sticky;
    bottom: 0;
    background: #fff;
    margin-bottom: -16px;
    z-index: 30;

    &-total {
      text-align: right;
      font-size: 16px;
      margin-left: auto;
      line-height: 22px;
    }

    &-button {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;

      &_text {
        font-size: 10px;
        margin-left: 10px;
        position: absolute;
        bottom: -15px;
      }
    }

    &-cart {
      position: relative;
      display: inline-block;

      &_text {
        font-size: 10px;
        position: absolute;
        bottom: -15px;
        width: 100%;
        text-align: center;
      }
    }

    .btn {
      flex-shrink: 0;
      min-width: 200px;
      margin-left: 10px;
    }
  }

  &__link {
    color: map-get($colors, black);
    text-decoration: none;

    &:hover {
      color: map-get($colors, black);
      text-decoration: underline;
    }
  }

  &-info {
    &__cover {
      div {
        $padding: 16px;
        top: $padding;
        bottom: $padding;
        left: $padding;
        right: $padding;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &__tabs {
      box-shadow: 0 -1px 0 0 #ECECEE inset;
    }

    &__tab {
      color: #505565;
      transition: all .2s;
      position: relative;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background: #562380;
        pointer-events: none;
        transition: all .2s;
        transform: translateY(2px);
        opacity: 0;
      }

      @include hover() {
        font-weight: 600;
        color: map-get($colors, secondary);
      }

      &._active {
        font-weight: 600;
        color: map-get($colors, secondary);
        cursor: default !important;

        &:after {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  &-table {
    border: 1px solid map_get($colors, mgray);
    border-radius: 8px;
    width: auto;
    flex-grow: 1;
    color: map-get($colors, text);

    &-header {
      line-height: 18px;
      padding: 0;
      position: relative;

      &__col {
        padding: 0 16px;

        &_name {
          width: 100%;
          display: flex;
          align-items: center;
          height: 100%;

          .table__sort {
            margin-left: 8px;
            margin-right: 0;
          }
        }

        &_article,
        &_cost,
        &_count {
          flex-shrink: 0;
        }

        &_article {
          width: 110px;
        }

        &_cost {
          width: 110px;
          text-align: right;
        }

        &_count {
          width: 128px;
        }
      }
    }

    &-body {
      > .catalog-table-row:last-child {
        border-radius: 0 0 8px 8px;
        border: 0;
      }

      > .catalog-table-accordion:last-child {
        border-radius: 0 0 8px 8px;

        &:not(._active) {
          > .catalog-table-row {
            border-radius: 0 0 8px 8px;
            border: 0;
          }
        }

        .catalog-table-row:last-child {
          border-radius: 0 0 8px 8px;
          border: 0;
        }
      }
    }

    &-row {
      min-height: 64px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid map_get($colors, mgray);
      padding: 16px 0;
      font-size: 14px;
      line-height: 16px;

      &__col {
        padding: 0 16px;

        &_name {
          width: 100%;
          display: flex;
          align-items: center;
        }

        &_article,
        &_cost,
        &_count {
          flex-shrink: 0;

          span {
            display: none;
            opacity: .4;
            font-weight: 400 !important;
          }
        }

        &_article {
          width: 110px;
        }

        &_cost {
          width: 110px;
          text-align: right;
          font-weight: 700;
        }

        &_count {
          width: 128px;
          text-align: right;
        }
      }

      &__sublevel {
        flex-shrink: 0;
        margin-right: 10px;
        align-self: flex-start;
        font-size: 14px;
        color: map-get($colors, dgray);
      }

      &__action {
        flex-shrink: 0;
        margin-left: auto;
        padding-left: 20px;
      }
    }

    &-accordion {
      transition: background .2s;

      &._active {
        background: #F6F6F9;

        .catalog-table-accordion__count {
          color: map-get($colors, primary);

          &:after {
            transform: scaleY(1);
          }
        }
      }

      > .catalog-table-row {
        justify-content: space-between;
        align-items: stretch;
        padding: 16px;
      }

      &__count {
        color: #000;
        font-weight: 400 !important;
        transition: color .2s;
        margin-top: 8px;

        &:after {
          content: $icon-arrow-up-round;
          font-family: $font_icomoon;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          display: inline-block;
          vertical-align: middle;
          color: #a09fa7;
          pointer-events: none;
          transition: transform .2s;
          transform: scaleY(-1);
          font-size: 8px;
          margin-left: 12px;
        }
      }

      &__sum {
        color: #656565;
        text-align: right;
        width: 120px;
        flex-shrink: 0;

        span {
          color: map-get($colors, text);
        }
      }

      .accordion__title {
        display: flex;
        align-items: center;
        width: 100%;
        font-weight: 700;
        cursor: pointer;
        position: relative;

        &:after {
          display: none;
        }
      }

      &._detail {
        .catalog-table-row {
          min-height: 0;
        }

        .accordion__title {
          cursor: default;
        }

        .catalog-table-accordion__count {
          margin: 0;
          color: #000 !important;

          &:after {
            display: none;
          }
        }
      }
    }

    &._block {
      .catalog-table-header {
        &__col {
          &_name {
            padding-left: 230px;
          }

          &_article,
          &_cost,
          &_count {
            display: none;
          }
        }
      }

      .catalog-table-row {
        padding: 0;

        &__col {
          padding: 0;

          &_wrap {
            padding: 32px 16px 32px 32px;
            width: 100%;
          }

          &_subwrap {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          &_article,
          &_cost,
          &_count {
            padding-top: 6px;

            span {
              display: inline;
            }
          }

          &_article {
            width: 100%;
            padding-top: 16px;
          }

          &_cost {
            width: calc(100% - 115px);
            flex-shrink: 1;
            text-align: left;
          }

          &_count {
            width: 96px;
            flex-shrink: 0;

            .btn {
              width: 96px;
              margin-left: auto;
            }
          }
        }

        &__pic {
          display: block;
          flex-shrink: 0;
          width: 198px;
          min-height: 156px;
          height: auto;
          position: relative;
          background: map-get($colors, white);

          div {
            position: absolute;
            top: 12px;
            left: 12px;
            right: 12px;
            bottom: 12px;
            max-height: 132px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        &__sublevel {
          margin-right: 16px;
        }
      }

      .catalog-table-accordion {
        > .catalog-table-row {
          padding: 0;
          justify-content: flex-start;
        }

        &__count {
          margin-top: 16px;
        }

        &__sum {
          padding: 32px 16px 32px 0;
          margin-left: auto;
        }

        .accordion__title {
          padding: 32px 0 32px 32px;
          align-items: flex-start;
        }

        .accordion__body {
          .catalog-table-row__col_article,
          .catalog-table-row__col_cost {
            padding-left: 30px;
          }
        }

        &._detail {
          .catalog-table-accordion__count {
            margin: 0;
          }

          .catalog-table-accordion__sum {
            padding: 16px;
          }

          .accordion__title {
            display: block;
            padding: 16px 0 16px 16px;
          }
        }
      }
    }
  }

  &-switch {
    position: absolute;
    right: 16px;
    top: 12px;
    border-radius: 3px;
    background: map-get($colors, white);
    height: 32px;
    display: flex;

    &__btn {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      font-size: 12px;
      color: map-get($colors, dgray);
      cursor: pointer;
      transition: color .2s;
      display: flex;
      align-items: center;
      justify-content: center;

      &.icon-route-sheet {
        font-size: 13px;
      }

      &:hover {
        color: map-get($colors, mtext);
      }

      &._active {
        pointer-events: none;
        color: map-get($colors, primary) !important;
        cursor: default;
      }
    }

    &__line {
      width: 1px;
      height: 32px;
      flex-shrink: 0;
      pointer-events: none;
      background: map-get($colors, mgray);
    }
  }

  &-icon {
    &__collapse {
      color: #9a9aa4;
      font-size: 12px;
    }
  }

  &-slider {
    &-navigation {
      .pages__arrow {
        width: 32px;
        height: 32px;
        border-radius: 3px;
        border: 1px solid map-get($colors, mgray);
        background: map-get($colors, white) !important;
        cursor: pointer;

        & + .pages__arrow {
          margin-left: 12px;
        }

        .icon {
          font-size: 13px;
          color: map-get($colors, mtext);
          transition: color .2s;
        }

        &:hover {
          .icon {
            color: map-get($colors, black);
          }
        }
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      border: 1px solid map-get($colors, mgray);
      width: 100%;

      &__photo {
        flex-shrink: 0;
        height: 192px;
        position: relative;

        div {
          $size: 15px;
          position: absolute;
          top: $size;
          left: $size;
          right: $size;
          bottom: $size;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      &__body {
        padding: 16px;
        font-size: 14px;
        line-height: 16px;
        color: map-get($colors, text);
        display: flex;
        height: 100%;
        flex-direction: column;
      }

      &__title {
        flex-shrink: 0;
      }

      &__article,
      &__price {
        margin-top: 8px;
        flex-shrink: 0;

        span {
          opacity: .4;
          font-weight: 400 !important;
        }
      }

      &__price {
        font-weight: 700;
      }
    }

    .swiper-slide {
      height: auto;
      display: flex;
    }
  }

  &__breadcrumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    width: 100%;

    &-link {
      color: #000;
      text-decoration: none;
      transition: color .2s;

      &:hover {
        text-decoration: none;
        color: map-get($colors, primary);
      }
    }
  }

  &__btn {
    &-filters {
      width: 130px;
      margin-left: 16px;
      box-shadow: none;
    }
  }

  .board__title {
    padding-top: 12px;
    padding-bottom: 12px;
    justify-content: space-between;

    .icon-arrow-right {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin: 1px 3px 0;
      text-align: center;
      line-height: 24px;
      font-size: 12px;
      pointer-events: none;
    }
  }

  .pages {
    margin: 0 16px 0 0;
    flex-shrink: 0;

    &__wrap {
      display: none;
    }

    &__item {
      cursor: pointer;
      color: map_get($colors, text);

      &._active {
        background-color: map_get($colors, dgray);
        color: map_get($colors, text);
      }

      @include hover() {
        background-color: map_get($colors, dgray);
        color: map_get($colors, text);
      }
    }

    &__arrow {
      color: map_get($colors, dgray) !important;

      @include hover() {
        color: map_get($colors, text) !important;
      }
    }
  }

  .form-search {
    max-width: 450px;
    flex-shrink: 0;
    width: 37%;
    margin-left: 20px;
    align-self: start;

    &[data-state='active'] {
      .form-search-autocomplete {
        display: block;
      }
    }

    .form-control {
      &:focus ~ .form-search-autocomplete {
        display: block;
      }
    }

    &-autocomplete {
      position: absolute;
      border-radius: 3px;
      padding: 16px;
      background: map-get($colors, white);
      box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
      z-index: 90;
      right: 0;
      width: 100%;
      top: 100%;
      margin-top: 8px;
      display: none;
      animation: fadeIn .3s backwards;
      min-width: 450px;

      &__item {
        display: block;
        font-size: 14px;
        line-height: 16px;
        color: map-get($colors, text);

        @include hover() {
          color: map-get($colors, primary);
          text-decoration: none;
        }

        & + .form-search-autocomplete__item {
          margin-top: 12px;
        }
      }

      ion-spinner {
        --color: #{map_get($colors, primary)};
      }
    }
  }
}


@media ($media_min_phone_landscape) {
  .catalog {
    &__btn {
      &-filters {
        display: none !important;
      }
    }
  }
}

@media ($media_min_phone_landscape) and ($media_max_tablet_landscape) {
  .catalog {
    &__footer {
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

@media ($media_min_phone_landscape) and (max-width: 900px) {
  .catalog {
    &-table {
      &-header {
        &__col {
          &_article,
          &_cost,
          &_count {
            display: none;
          }
        }
      }

      &-row {
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 16px;

        &__col {
          padding: 0;

          &_article,
          &_cost,
          &_count {
            span {
              display: inline;
            }
          }

          &_name {
            margin-bottom: 8px;
          }

          &_article {
            width: 100%;
          }

          &_cost {
            width: calc(100% - 115px);
            flex-shrink: 1;
            text-align: left;
          }

          &_count {
            width: 96px;
          }
        }
      }

      &-accordion {
        > .catalog-table-row {
          justify-content: start;
        }

        &__count {
          margin-top: 18px;
        }

        &__sum {
          width: 100%;
          margin-top: 12px;
        }

        .accordion__title {
          width: 100%;
        }

        .accordion__body {
          .catalog-table-row__col {
            &_name {
              padding-left: 0;
            }

            &_article,
            &_cost {
              padding-left: 25px;
            }
          }
        }

        &._detail {
          .catalog-table-row {
            min-height: 80px;
          }
        }
      }

      &-discount {
        &__label {
          display: none !important;
        }

        &__price {
          display: inline !important;
        }
      }

      &._block {
        .catalog-table-header {
          &__col {
            &_name {
              padding-left: 16px;
            }
          }
        }

        .catalog-table-row {
          align-items: flex-end;

          &__col {
            &_wrap {
              padding: 16px;
            }

            &_article {
              padding: 0;
            }

            &_cost {
              padding: 0;
            }

            &_count {
              padding: 0;
            }
          }

          &__pic {
            width: 100%;
            height: 264px;
            min-height: 0;

            div {
              max-height: none;
            }
          }

          &__sublevel {
            margin-right: 10px;
          }
        }

        .catalog-table-accordion {
          &__count {
            margin-top: 18px;
          }

          &__sum {
            padding: 0 16px 16px;
            margin: -4px 0 0 0;
            text-align: left;
          }

          .accordion__title {
            width: 100%;
            padding: 16px;
            align-items: center;
          }

          .accordion__body {
            .catalog-table-row__col_article,
            .catalog-table-row__col_cost {
              padding-left: 25px;
            }
          }

          &._detail {
            .catalog-table-accordion__sum {
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}

@media ($media_max_tablet) {
  .catalog {
    &__footer {
      .pages {
        display: none;
      }
    }

    .pages {
      &__wrap {
        display: block;
        margin-top: 25px;
        padding-bottom: 10px;
      }
    }
  }
}

@media ($media_max_phone_landscape) {
  .catalog {
    &__filter {
      display: none;
    }

    &-info {
      &__tabs {
        &:not([data-state='active']) {
          .catalog-info__tab {
            &._active {
              font-weight: 400;
              color: #505565;

              &:after {
                transform: translateY(2px);
                opacity: 0;
              }
            }
          }

          & ~ .catalog-info__tab-block {
            display: none;
          }
        }
      }

      &__title {
        font-size: 16px !important;
        line-height: 24px !important;
      }

      &__price {
        font-size: 28px !important;
        line-height: 32px !important;
      }

      h1 {
        font-size: 28px;
        line-height: 32px;
      }
    }

    &-table {
      &-header {
        &__col {
          &_name {
            font-size: 0;
            padding: 0;
            margin-left: 8px;
          }
        }
      }

      &._block {
        .catalog-table-header {
          &__col {
            &_name {
              padding-left: 80px;
            }
          }
        }
      }
    }

    .pages {
      justify-content: flex-start;
    }

    .form-search {
      position: absolute;
      max-width: none;
      top: -48px;
      left: 16px;
      right: 16px;
      width: auto;
      margin: 0;

      &-autocomplete {
        width: calc(100vw - #{$content_layout_padding * 2});
        min-width: 0;
      }
    }
  }
}

@media (max-width: 599px) {
  .catalog {
    &-table {
      &-header {
        &__col {
          &_article,
          &_cost,
          &_count {
            display: none;
          }
        }
      }

      &-row {
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 16px;

        &__col {
          padding: 0;

          &_article,
          &_cost,
          &_count {
            span {
              display: inline;
            }
          }

          &_name {
            margin-bottom: 8px;
          }

          &_article {
            width: 100%;
          }

          &_cost {
            width: calc(100% - 115px);
            flex-shrink: 1;
            text-align: left;
          }

          &_count {
            width: 96px;
          }
        }
      }

      &-accordion {
        > .catalog-table-row {
          justify-content: start;
        }

        &__count {
          margin-top: 18px;
        }

        &__sum {
          width: 100%;
          margin-top: 12px;
        }

        .accordion__title {
          width: 100%;
        }

        .accordion__body {
          .catalog-table-row__col {
            &_name {
              padding-left: 0;
            }

            &_article,
            &_cost {
              padding-left: 25px;
            }
          }
        }

        &._detail {
          .catalog-table-row {
            min-height: 80px;
          }
        }
      }

      &-discount {
        &__label {
          display: none !important;
        }

        &__price {
          display: inline !important;
        }
      }

      &._block {
        .catalog-table-header {
          &__col {
            &_name {
              padding: 0;
            }
          }
        }

        .catalog-table-row {
          align-items: flex-end;

          &__col {
            &_wrap {
              padding: 16px;
            }

            &_article {
              padding: 0;
            }

            &_cost {
              padding: 0;
            }

            &_count {
              padding: 0;
            }
          }

          &__pic {
            width: 100%;
            height: 264px;
            min-height: 0;

            div {
              max-height: none;
            }
          }

          &__sublevel {
            margin-right: 10px;
          }
        }

        .catalog-table-accordion {
          &__count {
            margin-top: 18px;
          }

          &__sum {
            padding: 0 16px 16px;
            margin: -4px 0 0 0;
            text-align: left;
          }

          .accordion__title {
            width: 100%;
            padding: 16px;
            align-items: center;
          }

          .accordion__body {
            .catalog-table-row__col_article,
            .catalog-table-row__col_cost {
              padding-left: 25px;
            }
          }

          &._detail {
            .catalog-table-accordion__sum {
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .catalog {
    &-table {
      &-header {
        &__col {
          &_name {
            display: flex;
            margin-right: 100px;
            margin-left: 10px;

            .table__sort {
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

@media ($media_max_phone) {
  .catalog {
    &__footer {
      .btn {
        min-width: 0;
      }
    }
  }
}
