@import "config";

@font-face {
  font-family: 'icomoon';
  src: url($fonts_dir + 'icomoon.ttf') format('truetype'),
  url($fonts_dir + 'icomoon.woff') format('woff'),
  url($fonts_dir + 'icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Regular'),
  local('Lato-Regular'),
  url($fonts_dir + 'latoregular.woff2') format('woff2'),
  url($fonts_dir + 'latoregular.woff') format('woff'),
  url($fonts_dir + 'latoregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Bold'),
  local('Lato-Bold'),
  url($fonts_dir + 'latobold.woff2') format('woff2'),
  url($fonts_dir + 'latobold.woff') format('woff'),
  url($fonts_dir + 'latobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Black'),
  local('Lato-Black'),
  url($fonts_dir + 'latoblack.woff2') format('woff2'),
  url($fonts_dir + 'latoblack.woff') format('woff'),
  url($fonts_dir + 'latoblack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
