@import 'config';

.filter {
  width: $filter_width;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  transform: translateX(-100%);
  left: $menu_width + $filter_width;
  will-change: transform;
  overflow: auto;

  &__wrap {
    width: $filter_width;
    flex-shrink: 0;
    height: 100%;
  }

  &__bg {
    display: none;
  }

  &__close {
    color: map_get($colors, text);
    top: 10px;
    right: 1px;
    display: none;
    background: #fff;
    width: 32px;
    height: 32px;
    line-height: 32px;
  }

  &__body {
    height: 100%;
    position: relative;
    overflow: auto;
    background-color: #fff;

    .accordion__title {
      position: relative;

      &:after {
        display: none;
      }

      .accordion__title-toggle {
        position: absolute;
        width: 18px;
        height: 18px;
        top: 3px;
        right: 0;
        color: #a09fa7;

        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 2px;
          background: currentColor;
          top: 8px;
          left: 4px;
        }

        &:after {
          transition: opacity .2s;
          transform: rotate(90deg);
        }
      }
    }
  }

  &__part {
    &.accordion._active,
    .accordion._active {
      > .accordion__title {
        .accordion__title-toggle:after {
          opacity: 0;
        }
      }
    }

    & ~ .filter__part {
      border-top: 1px solid map-get($colors, mgray);
    }

    > .accordion__title {
      padding: 16px 20px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      color: #110f24;

      .accordion__title-toggle {
        top: 15px;
        right: 20px;
      }
    }

    > .accordion__body {
      .accordion__body-wrap {
        padding: 0 20px 16px;
      }
    }

    &._brand {
      .accordion__body {
        .accordion__title {
          padding-right: 25px;
        }

        .accordion__body {
          padding-left: 30px;

          .custom-checkbox {
            margin-top: 8px;
          }
        }
      }

      > .accordion__body {
        .accordion__body-wrap {
          > .accordion,
          > .custom-checkbox {
            margin-top: 8px;

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }

    &._category {
      > .accordion__body {
        margin-top: -10px;

        .accordion__body-wrap {
          padding: 0 12px 16px;

          .accordion {
            &._active {
              > .accordion__title {
                color: map-get($colors, primary);
              }
            }

            &__title {
              min-height: 36px;
              display: flex;
              align-items: center;
              border-radius: 3px;
              padding: 8px 30px 8px 8px;
              font-size: 14px;
              line-height: 16px;
              color: map_get($colors, text);
              transition: color .2s;
              word-break: break-word;

              &:hover {
                color: map-get($colors, primary);
              }
            }

            &__body {
              border-radius: 8px;

              .filter-category__item {
                margin-bottom: 0;

                & + .filter-category__item {
                  margin-top: 2px;
                }
              }
            }
          }

          > .accordion {
            > .accordion__body {
              background: map-get($colors, mgray);
              padding: 4px 6px;
              margin-left: -8px;
              margin-right: -8px;

              .accordion__title {
                padding-left: 10px;

                .accordion__title-toggle {
                  right: 10px;
                }
              }

              > .filter-category__item {
                &._active {
                  background-color: map_get($colors, white);
                }

                @include hover() {
                  background-color: map_get($colors, white);
                }
              }

              .accordion__body {
                background: map-get($colors, white);
                padding: 4px;
                margin-left: -2px;
                margin-right: -2px;

                .filter-category__item {
                  padding-left: 8px;
                  padding-right: 8px;

                  &._active {
                    background-color: map_get($colors, mgray);
                  }

                  @include hover() {
                    background-color: map_get($colors, mgray);
                  }
                }
              }
            }
          }

          > .filter-category__item {
            padding-left: 8px;
            padding-right: 8px;
            margin-bottom: 0;

            &:hover {
              color: map-get($colors, primary);
              transition: color .2s;
            }
          }
        }

        .accordion__title {
          .accordion__title-toggle {
            top: 9px;
            right: 8px;
          }
        }
      }
    }

    &._properties {
      .accordion__body {
        .accordion__title {
          padding-right: 25px;

          .accordion__title-toggle {
            top: 0;
          }
        }

        .accordion__body {
          .custom-checkbox:first-child {
            margin-top: 13px;
          }
        }

        .accordion + .accordion {
          margin-top: 16px;
        }

        .custom-checkbox + .custom-checkbox {
          margin-top: 8px;
        }
      }
    }
  }

  &-category__item {
    min-height: 36px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    transition: background-color .2s;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 16px;
    color: map_get($colors, text);
    margin-bottom: 2px;
    position: relative;

    &._active {
      color: map-get($colors, primary);

      a {
        cursor: default;
      }
    }
  }

  &__footer {
    margin-top: auto;
    padding: 12px;
    flex-shrink: 0;
    display: none;
  }

  &__title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 13px;
  }
}


@media ($media_min_phone_landscape) and ($media_max_tablet_landscape) {
  .filter {
    width: $filter_width_tablet;
    left: $filter_width_tablet;

    &__wrap {
      width: $filter_width_tablet;

      & ~ .column-main .table-block__header-wrap._fixed {
        left: $filter_width_tablet + 16px;
      }
    }

    &__body {
      margin-top: 65px;
      border-radius: 8px 8px 0 0;

      .custom-checkbox {
        .custom-control-label {
          font-size: 12px !important;
        }
      }
    }

    &__part {
      > .accordion__title {
        padding: 16px;

        .accordion__title-toggle {
          right: 16px;
        }
      }

      > .accordion__body {
        .accordion__body-wrap {
          padding: 0 16px 16px;
        }
      }

      &._brand {
        .accordion__body {
          .accordion__body {
            padding-left: 18px;
          }
        }
      }

      &._category {
        > .accordion__body {
          .accordion__body-wrap {
            .accordion {
              &__title {
                padding-left: 4px;
              }
            }

            > .accordion {
              > .accordion__body {
                padding-left: 4px;
                padding-right: 4px;

                .accordion__title {
                  padding-left: 8px;

                  .accordion__title-toggle {
                    right: 8px;
                  }
                }

                > .filter-category__item {
                  padding-left: 8px;
                  padding-right: 8px;
                }

                .accordion__body {
                  .filter-category__item {
                    padding-left: 6px;
                    padding-right: 6px;
                  }
                }
              }
            }

            > .filter-category__item {
              padding-left: 4px;
              padding-right: 4px;
            }
          }

          .accordion__title {
            .accordion__title-toggle {
              right: 4px;
            }
          }
        }
      }
    }
  }
}

@media ($media_max_phone_landscape) {
  .filter {
    top: 0;
    left: 0;
    transition: transform .3s;
    width: $filter_width + 32px;
    padding-right: 32px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 32px;
      background: #fff;
    }

    &__wrap {
      overflow: hidden;
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      z-index: 100;

      html._show-filter & {
        pointer-events: auto;

        .filter {
          transform: translateX(0);

          &__bg {
            opacity: 1;
          }
        }
      }
    }

    &__bg {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .55);
      opacity: 0;
      display: block;
      transition: opacity .3s;
    }

    &__close {
      display: block;
    }

    &__footer {
      display: block;
      position: relative;
    }
  }
}
