@import 'config';
@import 'icon_names';

html {
  --ion-font-family: #{$font_lato};
}

body {
  color: map_get($colors, text);
  font-family: $font_lato;
  background-color: map_get($colors, mgray);
  min-width: $content_min_width;
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
}

h1, .h1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 900;
}

h2, .h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 900;
}

h3, .h3 {
  font-size: 28px;
  line-height: 32px;
  font-weight: 900;
}

h4, .h4 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

h5, .h5 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

h6, .h6 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

a {
  color: map_get($colors, text);
  transition: opacity .2s, color .2s;

  &:hover {
    color: map_get($colors, text);
    text-decoration: underline;
  }

  &:visited {
    color: map_get($colors, text);
  }

  &.btn-primary {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

* {
  outline: 0;
}

.container {
  &-fluid {
    display: flex;
    padding: 0;
    height: 100%;
  }
}

.column {
  &-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 $content_layout_padding;
    height: 100%;
    overflow: auto;
    position: relative;
  }
}

.form {
  &-group {
    margin-bottom: 16px;
  }

  &-control {
    $placeholder_color: rgba(17, 15, 36, .4);
    color: map_get($colors, text);
    height: 40px;
    background-color: map_get($colors, lgray);
    border: 1px solid map_get($colors, mgray);
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 3px;
    transition: color .2s;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 1000px map_get($colors, lgray) inset !important;
      -webkit-text-fill-color: map_get($colors, text);
    }

    &::-webkit-input-placeholder {
      color: $placeholder_color;
    }

    &::-moz-placeholder {
      color: $placeholder_color;
    }

    &:-moz-placeholder {
      color: $placeholder_color;
    }

    &:-ms-input-placeholder {
      color: $placeholder_color;
    }

    &:focus {
      color: map_get($colors, text);
      background-color: map_get($colors, lgray);
      border-color: map_get($colors, mgray);
      box-shadow: none;
    }

    &.disabled,
    &[disabled] {
      background-color: map_get($colors, lgray);
      color: $placeholder_color;
    }

    &__wrap {
      position: relative;
    }

    &__icon {
      position: absolute;
      right: 8px;
      top: 50%;
      opacity: .4;
      transform: translateY(-50%);
      cursor: pointer;
      transition: opacity .2s;

      &:hover {
        opacity: 1;
      }

      & ~ .form-control {
        padding-right: 30px;
      }
    }
  }
}

.btn {
  border-radius: 3px;
  font-size: 14px;
  font-weight: 700;
  border: none;
  min-height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  text-decoration: none;
  line-height: 1;
  transition: none !important;

  &:active {
    opacity: .9;
  }

  &:focus {
    box-shadow: none !important;
    border: none !important;
  }

  &-text {
    min-height: 0;
    padding: 0;
    font-weight: normal;
  }

  &-primary {
    background-image: linear-gradient(171.87deg, map_get($colors, primary) 25%, #be71ff 100%);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
    color: #fff;
  }

  &-red {
    background-color: map-get($colors, red);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
    color: #fff;
  }

  &-green {
    background-color: map-get($colors, green);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
    color: #fff;
  }

  &-secondary {
    background-color: map_get($colors, mgray);
    color: map_get($colors, text);

    &:hover,
    &:active,
    &:focus {
      background-color: map_get($colors, mgray) !important;
      color: map_get($colors, text) !important;
    }
  }

  &-white {
    background-color: #fff;
    box-shadow: 0 0 0 1px map_get($colors, mgray) inset;
    color: map_get($colors, text);
  }

  &-round {
    min-height: 24px;
    border-radius: 30px;
    padding: 0 8px;
    background-color: map_get($colors, secondary);
    color: #fff;
    font-size: 12px;
    font-weight: 400;

    &:hover,
    &:active,
    &:focus {
      background-color: map_get($colors, secondary) !important;
      color: #fff !important;
    }

    &-invert {
      color: map_get($colors, secondary);
      background-color: #fff;
      box-shadow: 0 0 0 1px map_get($colors, secondary) inset;

      &:hover,
      &:active,
      &:focus {
        background-color: #fff !important;
        color: map_get($colors, secondary) !important;
      }
    }

    .icon {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      margin-left: 4px;
      font-size: 10px;
      text-align: center;
      line-height: 16px;
      pointer-events: none;
    }
  }

  &-sm {
    min-height: 32px;
    font-size: 12px;
    padding: 0 12px;
  }

  &-lg {
    min-height: 48px;
    font-size: 16px;
    padding: 0 20px;
  }

  &-block {
    display: flex;
  }

  &-icon {
    padding: 0;
    width: 40px;
    font-size: 10px;

    &.btn-lg {
      width: 48px;
      font-size: 12px;

      &.btn-secondary {
        &.btn-icon-cart {
          font-size: 24px;

          .icon {
            opacity: .4;
          }
        }

        &.btn-icon-dollar {
          font-size: 28px;

          .icon {
            opacity: .4;
          }
        }
      }
    }

    &.btn-sm {
      width: 32px;
      font-size: 8px;
    }

    &-plus {
      font-size: 11px;

      .icon {
        transform: rotate(45deg);
      }
    }

    &-dollar {
      font-size: 24px;
    }

    &-cart {
      font-size: 20px;
    }

    &-basket {
      font-size: 18px;

      .icon {
        opacity: .4;
        color: #110f24;
      }
    }
  }

  &.disabled,
  &[disabled] {
    background: map_get($colors, dgray);
    color: rgba(17, 15, 36, .4);
    pointer-events: none;
    box-shadow: none;
    opacity: 1 !important;
  }

  &-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background-image: linear-gradient(135deg, map_get($colors, primary) 25%, #be71ff 100%);
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 36px;
      color: #fff;
      margin-left: 6px;
    }
  }

  &-close {
    position: absolute;
    width: 24px;
    height: 24px;
    font-size: 14px;
    text-align: center;
    line-height: 24px;
    cursor: pointer;
    transition: opacity .2s;
    z-index: 10;
  }

  &__badge {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: map_get($colors, secondary);
    text-align: center;
    color: #fff;
    line-height: 20px;
    font-size: 12px;
    font-weight: 700;
    pointer-events: none;
    margin-left: 5px;
  }

  .icon-close-round {
    margin-top: 2px;
  }
}

.table {
  border-radius: 8px;
  box-shadow: 0 0 0 1px map_get($colors, mgray) inset;
  width: auto;
  flex-grow: 1;

  &-responsive {
    position: relative;
    overflow: auto;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  &__sort {
    padding: 0 8px;
    cursor: pointer;
    transition: background .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 100%;
    min-width: 34px;

    @include hover() {
      background: map_get($colors, mgray);
    }
  }

  &-block {
    border: 1px solid map_get($colors, mgray);
    border-radius: 8px;
    width: auto;
    flex-grow: 1;
    color: map-get($colors, text);

    &__header {
      $height: 56px;
      background-color: map_get($colors, lgray);
      font-size: 16px;
      color: map_get($colors, text);
      height: $height;
      display: flex;
      align-items: center;
      border-radius: 8px 8px 0 0;
      font-weight: 700;
      border-bottom: 1px solid map_get($colors, mgray);
      padding: 0 14px;

      &-place {
        height: $height;
      }

      &-wrap {
        overflow: hidden;

        &._fixed {
          position: fixed;
          top: 0;
          z-index: 89;

          .table-block__header {
            border-radius: 0;
          }
        }

        &:not(._fixed) {
          width: auto !important;

          .table-block__header {
            margin: 0 !important;
            width: auto !important;
          }
        }
      }

      .table-block__col {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .table__sort {
        margin-right: -3px;
      }

      .dropdown {
        height: 100%;
        margin-left: -3px;

        &._active {
          .dropdown__body {
            background: map_get($colors, mgray);
          }

          .dropdown__filter {
            margin-top: 0;
          }
        }

        &__body {
          background: none;
          border: 0;
          border-radius: 0;
          padding: 0 8px;
          display: flex;
          align-items: center;
          height: 100%;
          transition: background .2s;

          &:after {
            display: none;
          }

          @include hover() {
            background: map_get($colors, mgray);
          }
        }

        &__filter {
          border-top-left-radius: 0;
          margin-top: -5px;
          font-weight: 400;
        }
      }
    }

    &__body {
      > .table-block__row:last-child {
        border-radius: 0 0 8px 8px;
        border: 0;
      }
    }

    &__row {
      min-height: 56px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid map_get($colors, mgray);
      padding: 10px 14px;
      font-size: 14px;
      line-height: 16px;

      .link__row > & {
        color: map_get($colors, text);
      }
    }

    &__col {
      flex-shrink: 0;
      padding: 0 8px;
    }
  }

  thead {
    th {
      background-color: map_get($colors, lgray);
      font-size: 16px;
      color: map_get($colors, text);
      height: 56px;
      border: none;
      padding: 9px 8px;
      line-height: 18px;
      vertical-align: middle;
      box-shadow: 0 1px 0 0 map_get($colors, mgray) inset;
      position: relative;

      &:first-child {
        padding-left: 20px;
        border-radius: 7px 0 0 0;
        box-shadow: 1px 1px 0 0 map_get($colors, mgray) inset;
      }

      &:last-child {
        padding-right: 20px;
        border-radius: 0 7px 0 0;
        box-shadow: -1px 1px 0 0 map_get($colors, mgray) inset;
      }
    }
  }

  tbody {
    td {
      color: #000;
      height: 56px;
      padding: 3px 8px;
      vertical-align: middle;
      border-top-color: map_get($colors, mgray);

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    }
  }

  .no-border {
    border: 0;

    th,
    td {
      border: 0;
    }
  }

  .icon-remove {
    width: 24px;
    height: 24px;
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    opacity: .4;
    cursor: pointer;
    display: inline-block;
    transition: opacity .2s;

    &:hover {
      opacity: 1;
    }
  }

  small {
    font-size: 12px;
    line-height: 16px;
  }
}
#datePicker-1_modal {
  padding-top: 50px;
  align-items: flex-start;
}

.custom {
  &-control {
    padding: 0;

    &-label {
      &:before {
        box-shadow: none !important;
        pointer-events: none !important;
      }

      &:after {
        pointer-events: none !important;
      }
    }
  }

  &-checkbox {
    $checkbox_size: 24px;

    &._circle {
      .custom-control-label {
        &:before {
          border-radius: 50%;
        }
      }
    }

    .custom-control-input {
      &[type='radio'] {
        & ~ .custom-control-label {
          &:before {
            border-radius: 50%;
          }
        }
      }

      &:active {
        & ~ .custom-control-label {
          &:before {
            background-color: map_get($colors, mgray);
          }
        }
      }

      &:checked,
      &:disabled:checked {
        & ~ .custom-control-label {
          &:before {
            background-color: map_get($colors, primary);
          }

          &:after {
            opacity: 1;
          }
        }
      }

      &._indeterminate:not(:checked) {
        & ~ .custom-control-label {
          &:before {
            background-color: map_get($colors, primary);
          }

          &:after {
            content: '';
            opacity: 1;
            left: 6px;
            top: 11px;
            height: 2px;
            width: 12px;
            background: #fff !important;
          }
        }
      }

      &:indeterminate {
        & ~ .custom-control-label {
          &:before {
            background-color: map_get($colors, mgray);
          }
        }
      }

      &:not(:disabled) {
        & ~ .custom-control-label {
          cursor: pointer;
        }
      }
    }

    .custom-control-label {
      font-size: 14px;
      line-height: 16px;
      padding-left: 32px;
      min-height: $checkbox_size;
      display: block;
      padding-top: 4px;
      word-break: break-word;

      &_sm {
        font-size: 12px;
      }

      &:before {
        top: 0;
        left: 0;
        width: $checkbox_size;
        height: $checkbox_size;
        border-radius: 3px;
        background-color: map_get($colors, mgray);
        border: 0;
      }

      &:after {
        content: $icon-check;
        transition: opacity .15s .1s;
        font-family: $font_icomoon;
        color: #fff;
        opacity: 0;
        left: 5px;
        top: 4px;
        font-size: 14px;
        width: auto;
        height: auto;
        background: none !important;
      }
    }
  }

  &-switch {
    .custom-control-input {
      &:checked {
        & ~ .custom-control-label {
          &:before {
            background-color: map_get($colors, primary);
          }

          &:after {
            transform: translateX(26px);
          }
        }
      }
    }

    .custom-control-label {
      font-size: 16px;
      line-height: 24px;
      padding-left: 68px;
      min-height: 32px;
      padding-top: 4px;

      &:before {
        top: 0;
        left: 0;
        width: 58px;
        height: 32px;
        transition: background-color .2s;
        border-radius: 50px;
        background-color: map_get($colors, mgray);
        border: 0;
      }

      &:after {
        transition: transform .2s;
        background: #fff !important;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        left: 2px;
        top: 2px;
      }
    }
  }
}

.text {
  &-muted {
    color: inherit !important;
    opacity: .4;
  }

  &-decoration {
    &-underline {
      text-decoration: underline;
    }

    &-through {
      text-decoration: line-through;
    }
  }
}

.row {
  margin-left: -8px;
  margin-right: -8px;
}

.col {
  padding-left: 8px;
  padding-right: 8px;
}

.progress {
  height: 4px;
  background-color: map_get($colors, mgray);

  &-bar {
    border-radius: 4px;
  }
}

.dropdown {
  &._active {
    .dropdown__body {
      &:after {
        transform: scaleY(1) translateY(-1px);
      }
    }

    .dropdown__popover {
      margin-top: 16px;
      opacity: 1;
      pointer-events: auto;
    }

    .dropdown__filter {
      margin-top: 8px;
      opacity: 1;
      pointer-events: auto;
    }
  }

  &._reverse {
    &._active {
      .dropdown__popover {
        margin-top: 0;
        margin-bottom: 16px;
      }
    }

    .dropdown__popover {
      top: auto;
      bottom: 100%;
      margin: 0 0 8px 0;

      &:before {
        top: 100%;
        bottom: auto;
        border: 6px solid transparent;
        border-top-color: #353c43;
      }
    }
  }

  &._fixed {
    .dropdown__filter {
      position: fixed;
      top: auto;
      left: auto;
      min-width: auto;
      right: auto;
    }
  }

  &.disabled {
    opacity: .4;
  }

  &_control {
    .dropdown {
      &__body {
        height: 40px;
        background: map_get($colors, lgray);
        border: 1px solid map_get($colors, mgray);

        &:after {
          top: 17px;
          color: #9a9aa4;
        }
      }

      &__text {
        color: map_get($colors, text);
      }
    }
  }

  &_btn {
    .dropdown {
      &__body {
        height: 40px;
        background: map_get($colors, mgray);
        border: 0;
        padding-left: 16px;
        padding-right: 38px;

        &:after {
          top: 18px;
          right: 16px;
          color: map_get($colors, text);
        }
      }

      &__text {
        color: map_get($colors, text);
        font-weight: 700;
      }
    }
  }

  &__body {
    background: #fff;
    border: 1px solid map_get($colors, mgray);
    border-radius: 3px;
    padding: 8px 30px 8px 8px;
    display: flex;
    align-items: center;
    position: relative;
    height: 32px;
    cursor: pointer;

    &:after {
      content: $icon-arrow-up-round;
      font-family: $font_icomoon;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      font-size: 6px;
      color: #a09fa7;
      pointer-events: none;
      transform: scaleY(-1);
      top: 13px;
      right: 10px;
      transition: transform .2s;
    }
  }

  &__icon {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    position: relative;
    pointer-events: none;

    &_sort {
      &._desc {
        div {
          &:before {
            width: 14px;
          }

          &:after {
            width: 5px;
          }
        }
      }

      &._active {
        div {
          color: map-get($colors, primary);
        }
      }

      div {
        position: absolute;
        left: 2px;
        top: 50%;
        width: 10px;
        height: 2px;
        margin-top: -1px;
        color: #9a9aa4;
        background-color: currentColor;
        transition: color .2s;

        &:before,
        &:after {
          content: '';
          position: absolute;
          left: 0;
          background-color: currentColor;
          height: 2px;
          transition: width .2s;
        }

        &:before {
          bottom: 4px;
          width: 5px;
        }

        &:after {
          top: 4px;
          width: 14px;
        }
      }
    }
  }

  &__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    pointer-events: none;
    overflow: hidden;
    flex-shrink: 0;
    width: 100%;
  }

  &__popover {
    $bg-color: #353c43;
    position: absolute;
    top: 100%;
    right: 0;
    background: $bg-color;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.0001),
    0 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    min-width: 100%;
    z-index: 89;
    transition: all .3s;
    opacity: 0;
    pointer-events: none;
    margin: 8px 0 0 0;
    font-size: 14px;
    color: #fff;
    line-height: 16px;

    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      right: 10px;
      pointer-events: none;
      border: 6px solid transparent;
      border-bottom-color: $bg-color;
    }

    &-wrap {
      max-height: 314px;
      overflow: auto;
      position: relative;
      padding: 8px 5px;
    }

    &-item {
      height: 32px;
      position: relative;
      padding: 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: opacity .2s;

      &:hover {
        opacity: .6;
      }

      & + .dropdown__popover-item {
        margin-top: 2px;
      }
    }
  }

  &__filter {
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, .1);
    border: 1px solid map-get($colors, mgray);
    border-radius: 8px;
    min-width: 100%;
    z-index: 89;
    transition: opacity .3s, margin .3s;
    opacity: 0;
    pointer-events: none;
    margin: 0;

    &-wrap {
      max-height: 270px;
      overflow: auto;
      position: relative;
      padding: 16px;

      & + .dropdown__filter-wrap {
        padding-top: 0;
      }
    }

    .custom-checkbox ~ .custom-checkbox {
      margin-top: 8px;
    }
  }
}


.fslightbox-toolbar-button {
  &:first-child {
    display: none !important;
  }
}

.debug-screen-width {
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 0;
  padding: 2px 3px;
  line-height: 1;
  background: #000;
  font-size: 8px;
  color: #fff;
  pointer-events: none;
}

.spinner-lines-small ~ .custom-control-label {
  &:before,
  &:after {
    display: none;
  }
}


@media ($media_min_tablet_landscape) {
  .table {
    &-shadow {
      border-radius: 8px;
      position: relative;
      overflow: hidden;

      &._left {
        &:before {
          display: block;
        }
      }

      &._right {
        &:after {
          display: block;
        }
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 16px;
        pointer-events: none;
        z-index: 10;
        display: none;
      }

      &:before {
        left: 0;
        background: linear-gradient(270deg, rgba(236, 236, 238, 0) 0%, rgba(236, 236, 238, .8) 100%);
        border-left: 1px solid map_get($colors, mgray);
      }

      &:after {
        right: 0;
        background: linear-gradient(270deg, rgba(236, 236, 238, .8) 0%, rgba(236, 236, 238, 0) 100%);
        border-right: 1px solid map_get($colors, mgray);
      }
    }
  }
}

@media ($media_max_tablet_landscape) {
  html {
    &._show-menu {
      overflow: hidden;
    }
  }

  body {
    overflow: auto;
  }

  .table {
    &-shadow {
      margin-left: -16px;
      margin-right: -16px;
    }

    &-responsive {
      &:before,
      &:after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }
    }

    &-block {
      &__header {
        &-wrap {
          &._fixed {
            left: 16px;
            display: flex;
            align-items: flex-start;

            &:before,
            &:after {
              content: '';
              display: block;
              width: 17px;
              height: 17px;
              flex-shrink: 0;
            }

            .table-block__header {
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }
}

@media ($media_max_phone_landscape) {
  html {
    &._show-filter {
      overflow: hidden;
    }
  }

  .container {
    &-fluid {
      display: block;
    }
  }

  .column {
    &-main {
      padding: 0;
    }
  }

  .btn-sm-block {
    display: flex;
    width: 100%;
  }

  .table {
    &-block {
      &__header {
        &-wrap {
          &._fixed {
            left: 0;
            transition: top .3s;
          }
        }
      }
    }
  }

  .d-none-phone {
    display: none !important;
  }
}
