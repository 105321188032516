@import "config";

.footer {
  margin: auto $content_layout_padding * -1 0 ($content_layout_padding - 1) * -1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
  padding: 26px 48px 26px 15px;
  background-color: #fff;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__item {
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 14px;
    flex-shrink: 0;
    line-height: 16px;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 108px;
    flex-wrap: wrap;

    .footer__item {
      width: 200px;
      padding-left: 32px;
      font-weight: 700;
    }
  }

  &__link {
    color: map_get($colors, text);
    transition: color .2s;

    &._active {
      color: map_get($colors, primary);
    }

    @include hover() {
      color: map_get($colors, primary);
    }
  }

  &__body {
    .footer__item {
      justify-content: flex-end;
    }
  }

  &__phone {
    font-weight: 700;

    a:hover {
      text-decoration: none;
    }
  }
}


@media ($media_max_phone_landscape) {
  .footer {
    margin: auto 0 0 0;
    padding: 6px 32px;
    display: block;

    &__menu {
      display: none;
    }

    &__body {
      .footer__item {
        justify-content: flex-start;
      }
    }
  }
}
