@import "config";

.menu {
  width: $menu_width;
  background-color: map_get($colors, secondary);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  transform: translateX(-100%);
  left: $menu_width;
  will-change: transform;

  &__wrap {
    width: $menu_width;
    flex-shrink: 0;
    height: 100%;
  }

  &__bg {
    display: none;
  }

  &__logo {
    height: $header_top_height + 1;
    background-color: rgba(17, 15, 36, .4);
    border-bottom: 1px solid #fff;
    padding: 12px 0 0 12px;
    position: relative;
    flex-shrink: 0;

    .link-abs {
      width: 100%;
    }

    img {
      width: 159px;
    }
  }

  &__close {
    color: #fff;
    top: 18px;
    right: 20px;
    display: none;

    @include hover() {
      opacity: .8;
    }
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
  }

  &__body {
    padding: 12px 12px 10px;
    flex-shrink: 0;
  }

  &__footer {
    margin-top: auto;
    padding: 12px 12px 10px;
    flex-shrink: 0;
    border-top: 1px solid rgba(17, 15, 36, .4);
    display: none;

    .menu-list {
      &__item {
        padding: 6px 10px;
      }
    }
  }

  &-list {
    &__item {
      min-height: 36px;
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 3px;
      transition: background-color .2s;
      padding: 6px 8px;
      font-size: 14px;
      line-height: 16px;
      color: #fff;
      margin-bottom: 2px;

      &._active {
        background-color: #6E339F;

        a {
          cursor: default;
        }
      }

      @include hover() {
        background-color: #6E339F;
      }
    }

    &__space {
      pointer-events: none;
      height: 14px;
    }

    .menu-list {
      margin: 12px -12px;
      background-image: linear-gradient(180deg, #fff 0%, #F6F6F9 97.34%);
      box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
      display: none;
    }
  }

  .icon-text {
    color: #fff;
  }
}


@media ($media_max_tablet_landscape) {
  .menu {
    top: 0;
    left: 0;
    transition: transform .3s;

    &__wrap {
      overflow: hidden;
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      z-index: 100;

      html._show-menu & {
        pointer-events: auto;

        .menu {
          transform: translateX(0);

          &__bg {
            opacity: 1;
          }
        }
      }
    }

    &__bg {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .55);
      opacity: 0;
      display: block;
      transition: opacity .3s;
    }

    &__logo {
      padding: 17px 0 0 20px;

      .link-abs {
        width: 60%;
      }

      img {
        width: 100px;
      }
    }

    &__close {
      display: block;
    }

    &__footer {
      display: block;
    }

    &-list {
      .menu-list {
        display: block;
      }
    }
  }
}
