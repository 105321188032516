$img_dir: "/assets/img/";
$fonts_dir: "/assets/fonts/";

$font_lato: "Lato", sans-serif;
$font_icomoon: "icomoon" !important;

$colors: (
  white: #fff,
  black: #000,
  primary: #934bcf,
  primary-o6: #be93e2,
  secondary: #562380,
  secondary-o6: #8050a7,
  green: #63ce63,
  orange: #ffbf2f,
  red: #e52b20,
  cred: #f00,
  text: #110f24,
  mtext: #a09fa7,
  dgray: #ccced0,
  mgray: #ececee,
  lgray: #f6f6f9,
  plan: #cf7a4b,
  fact: #4b50cf,
  t66: #666,
  t99: #999,
);

$content_min_width: 359px;
$content_layout_padding: 16px;
$header_top_height: 56px;
$header_bottom_height: 64px;
$menu_width: 240px;
$filter_width: 240px;
$filter_width_tablet: 220px;

$media_max_tablet_landscape: "max-width: 1199.98px";
$media_max_tablet: "max-width: 991.98px";
$media_max_phone_landscape: "max-width: 767.98px";
$media_max_phone: "max-width: 575.98px";
$media_min_tablet_landscape: "min-width: 1200px";
$media_min_tablet: "min-width: 992px";
$media_min_phone_landscape: "min-width: 768px";
$media_min_phone: "min-width: 576px";

@mixin hover {
  @media ($media_min_tablet_landscape) {
    &:hover {
      @content;
    }
  }
}
