@import "config";

.ion {
  &-content {
    --background: inherit;
    --keyboard-offset: 0 !important;
  }

  &-refresher {
    z-index: 200 !important;
  }

  &-loading {
    .loading-wrapper {
      border-radius: 50%;
      color: map_get($colors, primary);
      --spinner-color: #{map_get($colors, primary)};
      width: 76px;
      height: 76px;
    }
  }

  &-alert {
    &-error {
      --background: #{map_get($colors, red)};
      --color: #fff;
    }

    &-warning {
      --background: #{map_get($colors, plan)};
      --color: #fff;
    }

    &-info {
      --background: #{map_get($colors, primary)};
      --color: #fff;
    }

    &-success {
      --background: #{map_get($colors, green)};
      --color: #fff;
    }
  }

  &-app-web {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
  }
}
