@import "config";

.header {
  &__top {
    background-color: map_get($colors, secondary);
    align-items: center;
    display: none;
    height: $header_top_height;
  }

  &__bottom {
    height: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header__menu-trigger {
      color: map_get($colors, secondary);
      margin-left: -8px;
    }

    .icon-text__text {
      font-weight: 700;
      word-break: break-word;
    }

    .header__logo {
      margin-right: 40px;
    }
  }

  &__item {
    margin: 0 16px;
    opacity: .4;
    transition: opacity .2s;

    @include hover() {
      opacity: .8;
    }

    .icon-text__icon {
      margin-right: 0;
    }

    .icon-text__text {
      margin-left: 6px;
    }
  }

  &__menu-trigger {
    flex-shrink: 0;
    width: $header_top_height;
    height: $header_top_height;
    cursor: pointer;
    position: relative;
    color: #fff;

    div {
      $margin_top: 7px;
      position: absolute;
      top: 50%;
      left: 16px;
      width: 24px;
      height: 2px;
      margin-top: -1px;
      background-color: currentColor;
      pointer-events: none;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: currentColor;
      }

      &:before {
        margin-top: -$margin_top;
      }

      &:after {
        margin-top: $margin_top;
      }
    }
  }

  &__logo {
    width: 106px;
    flex-shrink: 0;
  }

  &__col {
    display: flex;
    align-items: center;
  }

  &__trigger-logo {
    display: none;
    align-items: center;
  }
}


@media ($media_min_phone_landscape) and ($media_max_tablet_landscape) {
  .header {
    &__bottom {
      &:not(._catalog) {
        height: $header_bottom_height;
      }

      &._catalog {
        .header__trigger-logo {
          position: fixed;
          left: 16px;
          top: 4px;
        }
      }

      ion-router-link {
        flex-shrink: 0;
      }
    }

    &__trigger-logo {
      display: flex;
    }
  }

  &__item {
    margin-left: 12px;
    margin-right: 12px;
  }
}

@media ($media_max_tablet) {
  .header {
    &__bottom {
      .icon-text__text {
        display: none;
      }
    }

    &__item {
      margin: 0 10px;
    }
  }
}

@media ($media_max_phone_landscape) {
  .header {
    padding-top: $header_top_height;

    &._fixed {
      & ~ .board .table-block__header-wrap._fixed {
        top: 56px;
      }
    }

    &__top {
      display: flex;
      position: absolute;
      top: $header_top_height;
      left: 0;
      width: 100%;
      z-index: 91;
      will-change: transform;
      transform: translateY(-100%);

      &._fixed {
        position: fixed;
        top: 0;
        transition: transform .3s;

        &._active {
          transform: translateY(0);
        }
      }
    }

    &__bottom {
      height: 0;

      &._catalog {
        height: $header_bottom_height;
      }
    }

    &__col {
      &.flex-shrink-0 {
        margin-right: 10px;
      }
    }

    &__item {
      margin: 0 6px;
    }
  }
}
