@import "config";

.auth {
  margin: auto;
  width: 100%;
  padding: 50px 0 80px;

  &__logo {
    width: 213px;
    display: block;
    margin: 0 auto 37px;
    transform: translateX(-20px);
  }

  &-form {
    padding-top: 24px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;

    &__link {
      &-forgot {
        opacity: .4;
        margin-top: 5px;
      }
    }
  }
}


@media ($media_max_phone_landscape) {
  .auth {
    width: auto;
    margin: 0;
    padding: 25px $content_layout_padding;

    &__logo {
      margin-bottom: 16px;
    }
  }
}
